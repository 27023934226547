import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth-service/auth.guard';
import {OpsGuard} from './auth-service/ops.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'ops',
    canActivate: [OpsGuard],
    loadChildren: () => import('./ops/ops.module').then((m) => m.OpsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
