import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(public authService: AuthService, public router: Router) {
  }

  canActivate(
      _route: ActivatedRouteSnapshot,
      _state: RouterStateSnapshot): boolean | UrlTree {
    if (this.authService.valid()) {
      return true;
    }
    return this.router.createUrlTree(['login']);
  }
}
