import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ACTION, AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OpsGuard  {
  constructor(
      public authService: AuthService,
      public router: Router,
      public toaster: ToastrService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.can(ACTION.canViewOps)) {
      return true;
    }
    this.toaster.error('Insufficient Privileges', 'Permissions Error');
    return this.router.createUrlTree(['/user']);
  }
}
