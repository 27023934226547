import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MaterialModule} from './material.module';
import {RouterModule} from '@angular/router';
import {AuthService} from './auth-service/auth.service';
import {DashboardService} from './services/dashboard.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth-service/auth.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {PipeModule} from './pipes/pipe.module';
import {ToastrModule} from 'ngx-toastr';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MailService} from './services/mail.service';
import {FullCalendarModule} from '@fullcalendar/angular';
import {MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {provideEnvironmentNgxMask} from 'ngx-mask';

const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD', // Customize this format based on your needs
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FullCalendarModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    MatDialogModule,
    PipeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 10,
    }),
    NgxMatTimepickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideEnvironmentNgxMask(),
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    DashboardService,
    AuthService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    MailService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
