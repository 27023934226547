import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(
      private toaster: ToastrService,
      private router: Router,
  ) {
  }

  deleteConfirmationDialog(title: string, onConfirmed: any) {
    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonColor: '#1684D3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirmed();
      }
    });
  }

  async promptRangeDialog(title: string, prompt: string, min: number, max: number, value: number) {
    const {value: num} = await Swal.fire({
      title: title,
      icon: 'question',
      showCancelButton: true,
      input: 'range',
      inputLabel: prompt,
      inputAttributes: {
        min: min.toString(),
        max: max.toString(),
        step: '1',
      },
      inputValue: value,
    });
    return num;
  }

  confirmationDialog(title: string, html: string, onConfirmed: any) {
    Swal.fire({
      title: title,
      html: html,
      showCancelButton: true,
      confirmButtonColor: '#1684D3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'YES',
      icon: 'question',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirmed();
      }
    });
  }

  async confirmDialog(title: string, html: string) {
    const {isDismissed} = await Swal.fire({
      title: title,
      html: html,
      showCancelButton: true,
      confirmButtonColor: '#1684D3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'YES',
      icon: 'question',
    });
    return !isDismissed;
  }

  systemErrorPopup(err: any) {
    const e = this.translateError(err);
    Swal.fire({
      icon: 'error',
      title: e.title,
      confirmButtonColor: '#1684d3',
      text: e.message,
    });
  }

  systemErrorToast(err: any) {
    const e = this.translateError(err);
    this.toaster.error(e.message, e.title);
  }

  success(message: string, title?: string) {
    this.toaster.success(message, title);
  }

  info(message: string, title?: string) {
    this.toaster.info(message, title);
  }

  warning(message: string, title?: string) {
    this.toaster.warning(message, title);
  }

  error(message: string, title?: string) {
    this.toaster.error(message, title);
  }

  configureProvider() {
    this.router.navigate(['/user/config/configuration']);
    this.warning('Please configure at least one email provider', 'Warning Message');
  }

  formInvalid(form: any) {
    if (form.invalid) {
      this.error('Please correct any form errors');
      return true;
    }
    return false;
  }

  handleSubscribeError(observable: Observable<Object>, next: (value: any) => void, cleanup: () => void = () => {}) {
    observable.subscribe({
      next,
      error: (err: any) => {
        this.systemErrorToast(err);
        cleanup();
      },
      complete: () => cleanup(),
    });
  }

  hideSubscribeError(observable: Observable<Object>, next: (value: any) => void, cleanup: () => void = () => {}) {
    observable.subscribe({
      next,
      error: (err: any) => {
        console.log(err);
        cleanup();
      },
      complete: () => cleanup(),
    });
  }

  private translateError(err: any) {
    if (err.name == 'HttpErrorResponse' && err.status == 0) {
      this.router.navigateByUrl('/offline');
      return {
        title: 'Network Error',
        message: 'There is a communication error with our systems. Please try again shortly!',
      };
    }
    if (err.name == 'HttpErrorResponse' && err.status == 400) {
      return {
        title: 'Bad Request',
        message: err.error.message || err.error.msg,
      };
    }
    if (err.name == 'HttpErrorResponse' && err.status == 404) {
      return {
        title: 'Resource not Found',
        message: err.error.message || err.error.msg,
      };
    }
    if (err.name == 'HttpErrorResponse' && err.status == 424) {
      return {
        title: 'Failed Dependency',
        message: err.error.message || err.error.msg,
      };
    }
    console.log(err);
    return {
      title: 'System Error',
      message: 'We got an unexpected response from our system. We are working on the problem.',
    };
  }

}
