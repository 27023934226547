import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AuthService} from '../auth-service/auth.service';
import {forkJoin, Observable, of} from 'rxjs';
import {DialogsService} from './dialogs.service';
import {getFolderIdByAttribute} from '../lib/nylas.helper';
import {FOLDERS, PROVIDER} from './globals';

const url: any = environment.API_URL;

const routes = {
  config: {
    list: () => `${url}/mail/config`,
    getConfig: (account: string) => `${url}/mail/${account}/config`,
    saveConfig: (account: string) => `${url}/mail/${account}/config`,
    deleteConfig: (account: string) => `${url}/mail/${account}/config`,
    setDefault: (account: string) => `${url}/mail/${account}/config/default`,
  },
  folders: {
    list: (account: string) => `${url}/mail/${account}/folders`,
  },
  folder: {
    count: (account: string) => `${url}/mail/${account}/folder/count`,
    create: (account: string) => `${url}/mail/${account}/folder`,
  },
  imap: {
    getProviders: () => `${url}/mail/imap`,
    getProviderById: (id: any) => `${url}/mail/imap/${id}`,
  },
  thread: {
    get: (account: string, threadId: string) => `${url}/mail/${account}/thread/${threadId}`,
  },
  threads: {
    list: (account: string) => `${url}/mail/${account}/threads`,
    getByContact: (account: string) => `${url}/mail/${account}/threads/byContact`,
  },
  message: {
    send: (account: any) => `${url}/mail/${account}/message`,
    attachment: (account: string) => `${url}/mail/${account}/message/attachment`,
  },
  messages: {
    get: (account: string) => `${url}/mail/${account}/messages`,
    trash: (account: string) => `${url}/mail/${account}/messages/trash`,
    move: (account: string) => `${url}/mail/${account}/messages/move`,
    star: (account: string) => `${url}/mail/${account}/messages/star`,
    unstar: (account: string) => `${url}/mail/${account}/messages/unstar`,
    read: (account: string) => `${url}/mail/${account}/messages/read`,
    unread: (account: string) => `${url}/mail/${account}/messages/unread`,
    opens: (account: string) => `${url}/mail/${account}/messages/opens`,
  },
};

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(
      private httpClient: HttpClient,
      public authService: AuthService,
      private dialogs: DialogsService,
  ) {
  }

  // --- [UTILS] ---

  addAllMailFolder(account: string, folders: any[]) {
    if (account === PROVIDER.GMAIL) {
      folders.push({
        'attributes': [FOLDERS.ARCHIVE],
        'id': null,
        'name': 'All Mail',
        'systemFolder': true,
        'totalCount': 0,
        'unreadCount': 0,
      });
    }
  }

  sortPreferredOrder(folders: any[]) {
    let preferredOrder = [
      FOLDERS.INBOX,
      FOLDERS.DRAFTS,
      FOLDERS.IMPORTANT,
      FOLDERS.ARCHIVE,
      FOLDERS.JUNK,
      FOLDERS.SENT,
      FOLDERS.TRASH,
    ];
    let newList = [];
    for (const attr of preferredOrder) {
      let idx = folders.findIndex((f: any) => f.attributes.includes(attr));
      if (idx >= 0) {
        newList.push(folders[idx]);
        folders.splice(idx, 1);
      }
    }
    folders.sort((a: any, b: any) => a.name.localeCompare(b.name, undefined, {sensitivity: 'accent'}));

    return newList.concat(folders);
  }

  folderStats(provider: string, callback: (stats: any) => void = () => {}, cleanup: () => void = () => {}) {
    const stats = {folderCount: -1, inboxCount: -1, draftCount: -1};

    this.dialogs.handleSubscribeError(
        this.folders(provider),
        (folders) => {
          stats.folderCount = folders.length;

          forkJoin([
            this.folderCount(provider, getFolderIdByAttribute(folders, FOLDERS.INBOX)),
            this.folderCount(provider, getFolderIdByAttribute(folders, FOLDERS.DRAFTS)),
          ]).subscribe({
            next: ([inboxCount, draftCount]) => {
              stats.inboxCount = inboxCount;
              stats.draftCount = draftCount;

              callback(stats);
              cleanup();
            },
            error: (err: any) => {
              this.dialogs.systemErrorToast(err);
              cleanup();
            },
          });
        },
        () => {
          cleanup();
        });
  }

  // --- [THREADS] ---

  listThreads(folderId: string, pageToken: any, limit: number, account: string, search: string | any) {
    return this.httpClient.put<any>(routes.threads.list(account), {folderId, pageToken, limit, search});
  }

  getThread(threadId: any, account: any) {
    return this.httpClient.get<any>(routes.thread.get(account, threadId));
  }

  getThreadsByContact(contactId: number, account: string) {
    return this.httpClient.put<any>(routes.threads.getByContact(account), {contactId});
  }

  // --- [FOLDERS] ---

  /**
   * Retrieve the number of messages in a given account folder
   * @param folderId
   * @param account
   */
  folderCount(account: string, folderId: string) {
    return folderId ? this.httpClient.put<any>(routes.folder.count(account), {folderId}) : of(-1);
  }

  /**
   * Retrieve the folders in account
   * @param account
   */
  folders(account: any) {
    return this.httpClient.get<any>(routes.folders.list(account));
  }

  folderCreate(name: string, account: any) {
    return this.httpClient.post<any>(routes.folder.create(account), {name});
  }

  // --- [CONFIG] ---

  getEnabledProviders() {
    return this.httpClient.get<any>(routes.config.list());
  }

  deleteProvider(account: any) {
    return this.httpClient.delete<any>(routes.config.deleteConfig(account));
  }

  /**
   * Retrieve the configuration for specified account
   * @param account
   */
  getConfig(account: any) {
    return this.httpClient.get<any>(routes.config.getConfig(account));
  }

  saveConfig(account: string, data: any) {
    return this.httpClient.post<any>(routes.config.saveConfig(account), data);
  }

  /**
   * Set the default email provider
   * @param account
   */
  setDefaultConfig(account: any) {
    return this.httpClient.put<any>(routes.config.setDefault(account), {});
  }

  // --- [IMAP PROVIDERS] ---
  getImapProviders(): Observable<any> {
    return this.httpClient.get<any>(routes.imap.getProviders());
  }

  getImapProviderById(id: any): Observable<any> {
    return this.httpClient.get<any>(routes.imap.getProviderById(id));
  }

  // --- [MESSAGES] ---

  sendEmail(account: string, data: any) {
    return this.httpClient.post<any>(routes.message.send(account), data);
  }

  getMessages(account: string, messageIds: string[]) {
    return this.httpClient.put<any>(routes.messages.get(account), {messageIds});
  }

  downloadAttachment(account: string, messageId: string, attachmentId: string) {
    return this.httpClient.put<any>(routes.message.attachment(account), {messageId, attachmentId});
  }

  markAsQuietly(messageIds: any, path: string, next: (value: any) => void, cleanup: () => void = () => {}) {
    if (messageIds.length == 0) return;
    this.dialogs.hideSubscribeError(this.httpClient.put<any>(path, {messageIds}), next, cleanup);
  }

  markAsReadQuietly(messageIds: any, account: any, next: (value: any) => void, cleanup: () => void = () => {}) {
    this.markAsQuietly(messageIds, routes.messages.read(account), next, cleanup);
  }

  markAsUnreadQuietly(messageIds: any, account: any, next: (value: any) => void, cleanup: () => void = () => {}) {
    this.markAsQuietly(messageIds, routes.messages.unread(account), next, cleanup);
  }

  markAsStarredQuietly(messageIds: any, account: any, next: (value: any) => void, cleanup: () => void = () => {}) {
    this.markAsQuietly(messageIds, routes.messages.star(account), next, cleanup);
  }

  markAsNotStarredQuietly(messageIds: any, account: any, next: (value: any) => void, cleanup: () => void = () => {}) {
    this.markAsQuietly(messageIds, routes.messages.unstar(account), next, cleanup);
  }

  markAsRead(messageIds: any, account: any) {
    return this.httpClient.put<any>(routes.messages.read(account), {messageIds});
  }

  markAsUnread(messageIds: any, account: any) {
    return this.httpClient.put<any>(routes.messages.unread(account), {messageIds});
  }

  markAsStarred(messageIds: any, account: any) {
    return this.httpClient.put<any>(routes.messages.star(account), {messageIds});
  }

  markAsNotStarred(messageIds: any, account: any) {
    return this.httpClient.put<any>(routes.messages.unstar(account), {messageIds});
  }

  trashMails(messageIds: any, account: any) {
    return this.httpClient.put<any>(routes.messages.trash(account), {messageIds});
  }

  moveEmail(folderId: any, messageIds: any, account: any) {
    return this.httpClient.put<any>(routes.messages.move(account), {messageIds, folderId});
  }

  // todo account is not required!
  getOpens(account: string, messageIds: any) {
    return this.httpClient.put<any>(routes.messages.opens(account), {messageIds});
  }
}
