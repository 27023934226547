// noinspection JSUnusedGlobalSymbols

// {
//   attributes: [ '\\HasNoChildren' ],
//       id: 'v0:98b30498-bcf0-41ff-afcc-1b6fe397fd97:Sync Issues/Local Failures',
//     name: 'Sync Issues/Local Failures',
//     systemFolder: false,
//     totalCount: 0,
//     unreadCount: 0
// },
// {
//   attribut

export function getFolderIdByName(folders: any[], name: string): string {
  let folder = folders.find((f:any) => f.name === name);
  return folder ? folder.id : null;
}

export function getFolderIdByAttribute(folders: any[], attribute: string): string {
  let folder = folders.find((f:any) => f.attributes.includes(attribute));
  return folder ? folder.id : null;
}

export function folderLabel(account: string) {
  return (account == 'gmail' || account == 'google') ? 'Label' : 'Folder';
}