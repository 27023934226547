import {HttpClient, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

const url: any = environment.API_URL;

const routes = {
  getProfile: () => `${url}/dashboard/getUserProfileData`,
  updateProfile: () => `${url}/dashboard/updateUserProfile`,
  changePassword: () => `${url}/dashboard/changePassword`,
  postImg: () => `${url}/dashboard/updateUserProfile/uploadImage`,
  postCompanyLogo: () => `${url}/dashboard/updateCompanyProfile/uploadImage`,
  listPipeLine: (id: any, year: any) => `${url}/dashboard/pipeline/${id}/${year}`,
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  constructor(
      private httpClient: HttpClient,
      public router: Router,
  ) {
  }

  listPipeline(id: number, year: number): Observable<any> {
    return this.httpClient.get<any>(routes.listPipeLine(id, year));
  }

  getProfile(): Observable<any> {
    return this.httpClient.get(routes.getProfile());
  }

  updateProfile(data: any): Observable<any> {
    return this.httpClient.patch(routes.updateProfile(), data);
  }

  changePassword(data: any): Observable<any> {
    return this.httpClient.patch(routes.changePassword(), data);
  }

  postImage(image: any, fileName: string): Observable<HttpEvent<any>> {
    //console.log(fileName);
    //console.log(image);
    const formData: FormData = new FormData();
    formData.append('image', image, fileName);
    return this.httpClient.post<any>(routes.postImg(), formData);
  }

  postCompanyImage(image: any, fileName: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('image', image, fileName);
    return this.httpClient.post<any>(routes.postCompanyLogo(), formData);
  }
}
